import styled from "@emotion/styled";
import { Box, Card, CardContent, Grid, Typography } from "@mui/material";



interface CardContents {
    Heading: string
    image: string
    List?: JSX.Element[]
}
// Create a custom Styled component using a call back function
const CustomHeadingTypography = styled(Typography)(() => ({
    fontFamily: 'Montserrat',
    color: 'black',
    textAlign: 'center',
    whiteSpace: 'pre-wrap',
    padding: '2px'
}));
export default function EducationCard(props: CardContents) {
    return (
        <Card sx={{
            backgroundColor: 'rgba(250, 255, 255, 0.5)',
            backdropFilter: 'blur(5px) saturate(100%)',
            borderRadius: 10,
            boxShadow: '0 0 20px 0 rgba(0, 0, 255, 0.5), 0 0 40px 0 rgba(0, 0, 255, 0.5), 0 0 80px 0 rgba(0, 0, 255, 0.5)',
            padding: '16px',
            margin: '0 auto',
        }}>
            <CardContent>
                <CustomHeadingTypography fontSize={30}>
                    {props.Heading}
                </CustomHeadingTypography>
                <Box sx={{
                    display: { xs: 'flex', md: 'flex' },
                    justifyContent: 'center',
                    marginTop: '2%',
                    width: '100%',
                    overflow: 'hidden',
                    height: '60vh',
                    background: `url(${props.image}) no-repeat center center`,
                    backgroundPosition: 'center',
                }}>

                </Box>
                <Grid container spacing={2} sx={{
                    display: 'flex',
                    justifyContent: 'center'
                }}>
                    {props.List}
                </Grid>
            </CardContent>
        </Card>
    );
}

