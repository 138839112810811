import { Grid } from "@mui/material"
import background from "../../assets/Africa.svg"
import data from "../../assets/data/contents.json"
import ExperienceCard from "./card"

const vivenBackend = data.experience.backedDeveloper.viven
const vivenDevops = data.experience.devops.viven
const hearX = data.experience.backedDeveloper.hearX
const scouch = data.experience.backedDeveloper.softwareCouch



const ExperiencePage = () => {
    return (
        <Grid container spacing={2} sx={{
            backgroundImage: `url(${background})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            width: '100%',
            height: '300vh',
            display: 'flex',
            justifyContent: 'center',
        }}  >
            <Grid item xs={10} md={10} sx={{
                marginTop: '5%'
            }}>
                <ExperienceCard
                    Company={hearX.name}
                    Position={hearX.position}
                    imageURL={hearX.image}
                    Description={hearX.description}
                    Timeline={hearX.timeline}
                />
            </Grid>
            <Grid item xs={10} md={10} >
                <ExperienceCard
                    Company={vivenDevops.name}
                    Position={vivenDevops.position}
                    imageURL={vivenDevops.image}
                    Description={vivenDevops.description}
                    Timeline={vivenDevops.timeline}
                />
            </Grid>
            <Grid item xs={10} md={10} >
                <ExperienceCard
                    Company={vivenBackend.name}
                    Position={vivenBackend.position}
                    imageURL={vivenBackend.image}
                    Description={vivenBackend.description}
                    Timeline={vivenBackend.timeline}
                />
            </Grid>
            <Grid item xs={10} md={10} >
                <ExperienceCard
                    Company={scouch.name}
                    Position={scouch.position}
                    imageURL={scouch.image}
                    Description={scouch.description}
                    Timeline={scouch.timeline}
                />
            </Grid>
        </Grid>)
}


export default ExperiencePage