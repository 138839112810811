import styled from "@emotion/styled";
import { Box, Card, CardContent, Grid, Typography } from "@mui/material";


interface CardContents {
    Position: string
    Company: string
    imageURL: string
    Description: string
    Timeline: string
    List?: JSX.Element[]
}
// Create a custom Styled component using a call back function
const CustomBodyTypography = styled(Typography)(() => ({
    '&:hover': {
        color: '#45269C', // Change the color to your desired color
    },
    fontSize: '20px',
    fontFamily: 'Concert One',
    whiteSpace: 'pre-wrap',
    color: '#45269C',
    overflowWrap: 'break-word',
    padding: '2px',
    textAlign: 'center',
}));

const CustomHeadingTypography = styled(Typography)(() => ({
    fontFamily: 'Montserrat',
    color: 'black',
    textAlign: 'center',
    whiteSpace: 'pre-wrap',
    padding: '2px'
}));
export default function ExperienceCard(props: CardContents) {
    return (
        <Card sx={{
            backgroundColor: 'rgba(250, 255, 255, 0.5)',
            backdropFilter: 'blur(5px) saturate(100%)',
            borderRadius: 10,
            boxShadow: '0 0 20px 0 rgba(0, 0, 255, 0.5), 0 0 40px 0 rgba(0, 0, 255, 0.5), 0 0 80px 0 rgba(0, 0, 255, 0.5)',
            padding: '16px',
            margin: '0 auto',
        }}>
            <CardContent>
                <CustomHeadingTypography fontSize={30}>
                    {props.Position}
                </CustomHeadingTypography>
                <Box sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    marginTop: '2%',
                    width: '100%',
                    overflow: 'hidden'
                }}>
                    <img alt="exp-img" src={props.imageURL}></img>
                </Box>

                <CustomHeadingTypography fontSize={25} >
                    {props.Company}
                </CustomHeadingTypography>
                <Box sx={{
                    display: 'flex',
                    justifyContent: 'flex-start'
                }}>
                    <CustomHeadingTypography fontSize={20} sx={{
                        mt: '2%'
                    }} textAlign={"left"}>
                        Timeline: {props.Timeline}
                    </CustomHeadingTypography>
                </Box>
                <CustomBodyTypography sx={{ mb: 1.5, mt: 1 }} >
                    {props.Description}
                </CustomBodyTypography>
                <Grid container spacing={2} sx={{
                    display: 'flex',
                    justifyContent: 'center'
                }}>
                    {props.List}
                </Grid>
            </CardContent>
        </Card>
    );
}

