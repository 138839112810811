import { createTheme } from "@mui/material";

const theme = createTheme({
    palette: {
        primary: {
            main: '#020307',
        },
        secondary: {
            main: '#B8B9BD',
        },
    },
    typography: {
        fontFamily: 'Roboto, sans-serif',
        fontSize: 16,
        fontWeightRegular: 400,

    },
    spacing: 8,
});

export default theme
