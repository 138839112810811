import { GitHub, Instagram, LinkedIn, MenuBook } from "@mui/icons-material";
import { AppBar, Box, IconButton, Menu, MenuItem, Toolbar } from "@mui/material";
import { useState } from "react";
import { Link } from "react-router-dom";
import logo from "../assets/hello.svg";



const MyAppBar = () => {
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event: any,) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);

    };



    const navigationLinks = createNavLinksComponent([
        {
            to: '/personal-info',
            label: 'Who Am I ?'
        },
        {
            to: '/experience',
            label: 'Work Experience'
        },
        {
            to: '/education',
            label: 'Education'
        },
    ])
    return (
        <AppBar position="relative" sx={{
            display: 'flex',
            justifyContent: 'space-between',
            // backgroundColor: 'bl'
        }}>
            <Toolbar sx={{
                mt: '8vh',
            }} >
                {/* <MobileMenu sx /> */}
                <IconButton
                    size="large"
                    edge="start"
                    color="inherit"
                    aria-label="menu"
                    sx={{ display: { xs: 'block', md: 'none' } }}
                    onClick={handleClick}
                >
                    <MenuBook />
                </IconButton>
                <Menu
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                    transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                >
                    <MenuItem onClick={handleClose} component={Link} to="/">Home</MenuItem>
                    <MenuItem onClick={handleClose} component={Link} to="/personal-info">Who Am I?</MenuItem>
                    <MenuItem onClick={handleClose} component={Link} to="/experience">Work Experience</MenuItem>
                    <MenuItem onClick={handleClose} component={Link} to="/education">Education</MenuItem>
                    <MenuItem onClick={handleClose}>Contact</MenuItem>

                </Menu>
                <Box sx={{
                    display: { xs: 'none', md: 'flex' },
                    justifyContent: 'space-between',
                    width: '5%',
                    flexGrow: 1,
                    marginLeft: '2%',
                    padding: '2px'
                }} >
                    {navigationLinks}
                </Box>

                <Box sx={{
                    display: { xs: 'flex', md: 'flex' },
                    width: '5%',
                    flexGrow: 1,
                    // marginLeft: '4%',
                    // marginBottom: '1%',
                    justifyContent: 'center',
                    // backgroundColor: 'yellow'
                }}>
                    <Link to={'/'}>
                        <img src={logo} alt="Logo" style={{
                            width: '100px',
                            height: '70px',
                            position: 'relative',
                            top: '-20px'
                        }} />
                    </Link>
                </Box>

                <Box sx={{
                    display: { xs: 'none', md: 'flex' },
                    flexGrow: 1,
                    width: '5%',
                    justifyContent: 'center',
                    position: 'relative',
                    left: '-6%',
                    // backgroundColor: 'yellow'
                }}>
                    <IconButton >
                        <Instagram sx={{
                            color: 'white',
                            '&:hover': {
                                color: '#01D5A2',
                            },
                        }} />
                    </IconButton>
                    <a href="https://github.com/GabDewraj" target="_blank" rel="noopener noreferrer">
                        <IconButton >
                            <GitHub sx={{
                                color: 'white',
                                '&:hover': {
                                    color: '#01D5A2',
                                },
                            }} />
                        </IconButton>
                    </a>
                    <a href="https://www.linkedin.com/in/gabriel-dewraj-8061681a2?lipi=urn%3Ali%3Apage%3Ad_flagship3_profile_view_base_contact_details%3B6njpFuUwQLWCuV3DYusxoA%3D%3D" target="_blank" rel="noopener noreferrer">
                        <IconButton >
                            <LinkedIn sx={{
                                color: 'white',
                                '&:hover': {
                                    color: '#01D5A2',
                                },
                            }} />
                        </IconButton>
                    </a>
                </Box>
            </Toolbar>
        </AppBar >)
}

interface IconButtonProps {
    icon?: string;
    label: string;
    onClick?: () => void;
    disabled?: boolean;
    className?: string;
    to: string;
}



const createNavLinksComponent = (links: IconButtonProps[]) => {
    return (
        links.map((link: IconButtonProps) => (
            <IconButton
                // edge="start"
                key={link.label}
                // onClick={handleCli}

                sx={{
                    my: 2,
                    display: 'block',
                    fontFamily: 'Merriweather',
                    fontStyle: 'normal',
                    fontWeight: '700',
                    fontSize: '20px',
                    lineHeight: '18px',
                    textDecoration: 'none',
                    /* identical to box height */
                    label: {
                        textDecoration: 'none',
                    },
                    textAlign: 'center',
                    color: '#FFFFFF',
                }}
            >
                <Link to={link.to}>
                    {link.label}
                </Link>
            </IconButton >


        )))
}


export default MyAppBar;