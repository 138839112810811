import styled from '@emotion/styled';
import { Grid } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';



interface CardContents {
    Heading: string
    Body?: string
    List?: JSX.Element[]
}
// Create a custom Styled component using a call back function
const CustomBodyTypography = styled(Typography)(() => ({
    '&:hover': {
        color: '#01D5A2', // Change the color to your desired color
    },
    fontSize: '20px',
    fontFamily: 'Concert One',
    whiteSpace: 'pre-wrap',
    overflowWrap: 'break-word',
    padding: '2px',
    textAlign: 'center',
}));

const CustomHeadingTypography = styled(Typography)(() => ({
    '&:hover': {
        color: 'yellow', // Change the color to your desired color
    },
    fontSize: '30px',
    fontFamily: 'Concert One',
    textAlign: 'center',
    whiteSpace: 'pre-wrap',
    padding: '2px'
}));
export default function BasicCard(props: CardContents) {
    return (
        <Card sx={{
            backgroundColor: 'rgba(30, 170, 200, 0.55)',
            backdropFilter: 'blur(10px) saturate(150%)',
            borderRadius: 10,
            boxShadow: '0 0 20px 0 rgba(0, 0, 255, 0.5), 0 0 40px 0 rgba(0, 0, 255, 0.5), 0 0 80px 0 rgba(0, 0, 255, 0.5)',
            padding: '16px',
            maxWidth: '600px',
            margin: '0 auto',
        }}>
            <CardContent>
                <CustomHeadingTypography variant="h5" style={{
                    fontSize: '30px',
                    fontFamily: 'Concert One',
                    textAlign: 'center',
                    whiteSpace: 'pre-wrap',
                    padding: '2px'
                }}>
                    {props.Heading}
                </CustomHeadingTypography>
                <CustomBodyTypography sx={{ mb: 1.5, mt: 3 }} color="white" fontFamily="Concert One" >
                    {props.Body}
                </CustomBodyTypography>
                <Grid container spacing={2} sx={{
                    display: 'flex',
                    justifyContent: 'center'
                }}>
                    {props.List}
                </Grid>
            </CardContent>
        </Card>
    );
}

