import styled from "@emotion/styled";
import { Grid, Typography } from "@mui/material";


export default function TechList({ stringList, delimiter, colLength }: { stringList: string; delimiter: string; colLength: number }) {
    const allListItems = stringList.split(delimiter).map((item, index) => (
        <li key={index}>{item}</li>
    ));
    const finalLIST = createMultiColList(allListItems, colLength);
    return finalLIST;
}

// Create a custom Styled component using a call back function
const CustomTypography = styled(Typography)(() => ({
    '&:hover': {
        color: '#01D5A2', // Change the color to your desired color
    },
    fontSize: '20px',
    fontFamily: 'Concert One',
    whiteSpace: 'pre-wrap',
    overflowWrap: 'break-word',
    padding: '2px',
}));



function createMultiColList(items: JSX.Element[], colLength: number) {
    var compiledLists: JSX.Element[] = [];
    var sublist: JSX.Element[] = [];
    var subListCounter = 0;
    for (let i = 0; i < items.length; i++) {
        sublist.push(
            <CustomTypography sx={{ mb: 1.5, mt: 3 }} color="white">
                {items[i]}
            </CustomTypography>
        );
        subListCounter++;
        if (subListCounter === colLength) {
            subListCounter = 0;
            compiledLists.push(
                <Grid item key={compiledLists.length}>
                    {sublist}
                </Grid>
            );
            sublist = [];
        }
    }
    if (sublist.length > 0) {
        compiledLists.push(
            <Grid item key={compiledLists.length}>
                {sublist}
            </Grid>
        );
    }
    return compiledLists;
}
