// Import required libraries



import { Route, Routes } from "react-router-dom";
import MyAppBar from "./components/cv-app-bar";
import EducationPage from "./pages/education/pages";
import ExperiencePage from "./pages/experience/page";
import HomePage from "./pages/home";
import PortfolioPage from "./pages/personalInfo/page";

function App() {
  return (
    <div className="App">
      <MyAppBar />
      <Routes>
        <Route path="/" element={<HomePage />} />
      </Routes>
      <Routes>
        <Route path="/personal-info" element={<PortfolioPage />} />
      </Routes>
      <Routes>
        <Route path="/experience" element={<ExperiencePage />} />
      </Routes>
      <Routes>
        <Route path="/education" element={<EducationPage />} />
      </Routes>
    </div >
  );
}

export default App;
