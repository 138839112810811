import { Grid } from "@mui/material"
import degree from "../../assets/degree.png"
import background from "../../assets/unsplash_yVUQlyRlJSw.svg"
import EducationCard from "./card"

const EducationPage = () => {
    return (
        <Grid container spacing={2} sx={{
            backgroundImage: `url(${background})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            width: '100%',
            height: '85vh',
            display: 'flex',
            justifyContent: 'center',
        }}  >
            <Grid item xs={10} md={5} sx={{
                marginTop: '5%'
            }}>
                <EducationCard image={degree} Heading="Education" />
            </Grid>

        </Grid>)
}


export default EducationPage