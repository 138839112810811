

import { Box, Card, CardContent, CardMedia, Container, Typography } from "@mui/material";
import devpic from "../assets/gabriel.png";
import theme from "../theme";




const HomePage = () => {
    // const navigationLinks = createNavLinksComponent(['Portfolio', 'About Me', 'Get In Touch'])
    return (
        <Container className={'homelink'} sx={{
            flexDirection: 'column',
            position: 'relative',
            left: '0px',
            display: 'flex',
            alignItems: 'center', // This centers the item vertically
            justifyContent: 'center', // This centers the item horizontally
            height: '80vh',
        }} maxWidth={false}>
            <Box sx={
                {
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    marginTop: '8%'
                }
            } >
                <Typography variant="h1"
                    style={
                        {
                            fontFamily: 'Merriweather',
                            fontWeight: '900px',
                            fontSize: '40px',
                            textAlign: 'center',
                            color: '#FFFFFF',
                            // marginBottom: '5%'
                        }}>
                    Gabriel Dewraj
                </Typography>
                <Typography variant="h1"
                    style={
                        {
                            fontFamily: 'Merriweather',
                            fontWeight: '900px',
                            fontSize: '30px',
                            textAlign: 'center',
                            color: '#01D5A2',
                        }}>
                    Solutions Engineer
                </Typography>
                <Typography variant="h1"
                    style={
                        {
                            fontFamily: 'Merriweather',
                            fontWeight: '900px',
                            fontSize: '30px',
                            textAlign: 'center',
                            color: '#FFFFFF',
                            marginBottom: '2%'
                        }}>
                    Full Stack & AWS Developer
                </Typography>
                <Card
                    className={"profile-pic"}
                    sx={{
                        borderRadius: '50%',
                        // position: 'relative',
                        // top: '20%',
                        // overflow: 'hidden',
                        width: theme.spacing(40),
                        height: theme.spacing(40),
                        border: "2px solid #01D5A2",
                        // marginTop: '5%'
                    }}>
                    <CardContent sx={{
                        backgroundColor: 'black',
                    }} >
                        <CardMedia
                            component="img"
                            alt="picture"
                            src={devpic}
                            className={"media"}
                            sx={{
                                height: '100%',
                                width: '100%',
                                borderRadius: '50%',
                            }}
                        />
                    </CardContent>
                </Card>
            </Box>
        </Container >
    );
};









export default HomePage;
