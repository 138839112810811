import { Box, Grid } from "@mui/material";
import mySvg from '../../assets/astronaut.svg';
import data from "../../assets/data/contents.json";
import BasicCard from "./card";
import TechList from "./list";
const professionalSummary = data.personalInfo.professional_summary
const techlistData = data.personalInfo.Tech.body
const technologiesList = TechList({
    stringList: techlistData,
    colLength: 4,
    delimiter: '\n'
})

const competencies = data.personalInfo.professional_competencies
const competenciesList = TechList({
    stringList: competencies.list.join(','),
    colLength: 10,
    delimiter: ','
})

const personalBeliefs = data.personalInfo.personal_beliefs
const PortfolioPage = () => {
    return (
        <Grid container spacing={2} sx={{
            backgroundImage: `url(${mySvg})`,
            backgroundSize: 'contain',
            width: '100%',
            height: '85vh',
        }}  >
            <Grid item xs={12} md={4} style={{
                marginTop: '4%',
                marginLeft: '2%'

            }}><Box sx={{ mb: '8%' }}>
                    <BasicCard
                        Body={professionalSummary.body}
                        Heading={professionalSummary.heading}
                    />
                </Box>
                <Box sx={{ mb: '8%' }}>
                    <BasicCard
                        Heading={personalBeliefs.heading}
                        Body={personalBeliefs.body} />
                </Box>
            </Grid>
            <Grid item xs={12} md={3} style={{
                marginTop: '2%',
                position: 'relative',
                marginLeft: '3%'
            }}>
                <BasicCard
                    Heading={competencies.heading}
                    List={competenciesList}
                />
            </Grid>
            <Grid item xs={12} md={4} sx={{
                marginTop: '6%',
                position: 'relative',
                marginLeft: '3%'
            }} style={{
                height: '1vh'
            }}>
                <BasicCard
                    Heading={'Tech List'}
                    List={technologiesList}
                />
            </Grid>
            {/* </Grid> */}

        </Grid >
    )
}
export default PortfolioPage;